/*
  @see: https://css-tricks.com/lazy-loading-images-with-vue-js-directives-and-intersection-observer/
*/
let count = 0;
export default {
  mounted: (el, binding) => {
    let dur = 2000 + count++ * 100;
    el.classList.add(`animate-pulse`);
    el.style.animationDuration = `${dur}ms`;

    function loadImage() {
      const imageElement = el; // Array.from(el.children).find((el) => el.nodeName === 'IMG');
      if (imageElement) {
        imageElement.addEventListener('load', () => {
          setTimeout(() => el.classList.remove('animate-pulse'), 100);
        });
        imageElement.addEventListener('error', () => console.log('error'));
        imageElement.src = binding.value || imageElement.dataset.url;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }
    if (window['IntersectionObserver']) {
      createObserver();
    } else {
      loadImage();
    }
  },
};
