import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import SubdomainLink from '@/Components/SubdomainLink';
import LazyLoadDirective from '@/Directives/LazyLoadDirective';
import { Workbox } from 'workbox-window';
import { Inertia } from '@inertiajs/inertia';

const el = document.getElementById('app');
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'ONE Audiobooks';

createInertiaApp({
	title: (title) => {
		return `${title} - ${appName}`;
	},
	resolve: (name) => import(`./Pages/${name}.vue`),
	setup({ el, app, props, plugin }) {
		let createdApp = createApp({ render: () => h(app, props) });

		const getSvgHtml = require.context('../svgs/', true, /\.svg$/);
		// Configure and Mount Vue App
		if (process.env.NODE_ENV === 'production') {
			Bugsnag.start({
				apiKey: 'f1b9c7fccb05196d0163e7c2d83610e8',
				plugins: [new BugsnagPluginVue()],
			});

			createdApp.use(Bugsnag.getPlugin('vue'));
		}

		return createdApp
			.use(plugin)
			.component('subdomain-link', SubdomainLink)
			.directive('lazyload', LazyLoadDirective)
			.mixin({
				methods: {
					route,
					$getSvgHtml: (name) => {
						return getSvgHtml(`./${name}.svg`);
					},
					$getIconHtml: (name, lib = null) => {
						let path = lib ? `./icons/${lib}/${name}.svg` : `./icons/${name}.svg`;
						return getSvgHtml(path);
					},
				},
			})
			.mount(el);
	},
});

InertiaProgress.init({ color: '#2f61e9' });

// Service Worker
if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		const wb = new Workbox(`${window.location.origin}/service-worker.js`);
		wb.register();
	});
}

// Handle inertia errors
if (process.env.NODE_ENV === 'production') {
	let justLoaded = new Date().getTime();
	Inertia.on('invalid', (event) => {
		event.preventDefault();

		if (event?.isTrusted !== false) Bugsnag.notify(event);
		console.error(event);

		setTimeout(() => {
			if (new Date().getTime() - justLoaded > 1000) {
				document.location.reload(true);
			}
		}, 500);
	});
}
