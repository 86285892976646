<template>
  <component
    :is="useLinkComponent ? 'Link' : 'a'"
    :href="linkHref"
    @click="visitLink"
    :target="target"
  >
    <slot />
  </component>
</template>

<script>
  import { Link } from '@inertiajs/inertia-vue3';

  export default {
    name: 'SubdomainLink',

    props: {
      href: String,
      root: {
        type: Boolean,
        default: false,
      },
      direct: {
        type: Boolean,
        default: false,
      },
      target: {
        type: String,
        default: '_self',
      },
    },

    components: {
      Link,
    },

    computed: {
      useLinkComponent() {
        return this.target === '_self';
      },

      currentSubdomain() {
        return this.getSubdomain(window.location.host);
      },

      linkedSubdomain() {
        return this.getSubdomain(this.href);
      },

      linkHref() {
        return this.root && this.currentSubdomain
          ? this.href.replace(`${this.currentSubdomain}.`, '')
          : this.href;
      },
    },

    methods: {
      getSubdomain(url) {
        url = url.replace('https://', '');
        let isLocal = url.indexOf('localhost') !== -1;
        let parts = url.split('.');
        let min = isLocal ? 1 : 2;
        return parts.length > min ? parts[0] : null;
      },

      visitLink() {
        if (
          this.useLinkComponent &&
          ((this.root && this.currentSubdomain) ||
            this.currentSubdomain !== this.linkedSubdomain ||
            this.direct)
        ) {
          // Force entire page visit/hard refresh
          window.location.href = this.linkHref;
        }
      },
    },
  };
</script>
